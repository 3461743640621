import React from "react"
// import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
// import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { PhoneIcon } from '@heroicons/react/24/outline'
// import { Formik, Form } from "formik"
// import * as Yup from 'yup'
import Layout from "../components/layout"
import Heading from "../components/heading"

// const pages = [
//   { name: 'Contact Us', href: '/contact-us', current: true },
// ]

// const INITIAL_FORM_STATE = {
//   firstName: '',
//   lastName: '',
//   email: '',
//   mobile: '',
//   subject: '',
//   message: ''
// }

// const FORM_VALIDATION = Yup.object().shape({
//   firstName: Yup.string()
//     .required('Required'),
//   lastName: Yup.string()
//     .required('Required'),
//   email: Yup.string()
//     .email('An invalid email address has been detected.')
//     .required('Required'),
//   mobile: Yup.string()
//     .required('Required')
//     .matches(/^[0-9]+$/, "An invalid mobile number has been detected - please try again.")
//     .min(10, 'Must be an Australian Mobile number containing 10 digits (excluding country code)')
//     .max(10, 'Must be an Australian Mobile number containing 10 digits (excluding country code)'),
//   subject: Yup.string(),
//   message: Yup.string()
// })

const FacebookIcon = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
      clipRule="evenodd"
    />
  </svg>
)

const ContactPage = () => {

  // function submitForm(values) {
  //   JSON.stringify(values)
  // }

  return (
    <Layout
      title="Dimension Lab | Contact Us"
      description="Contact Dimension Lab to enquire or to request a quote."
      pageUrl="/contact-us"
      theme='light'
      logo={
        <StaticImage
          src="../images/lightLogo.svg"
          alt="Workflow"
          placeholder="none"
          loading="eager"
          height={50}
        />
      }
    >
      {
        <Heading
          heading="Contact Us"
          subheading="Let's Get Started"
          bg={
            <StaticImage
              src="https://images.unsplash.com/photo-1531537571171-a707bf2683da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
              alt="Banner for the Contact Us Page"
              placeholder="none"
              loading="eager"
              objectFit="fullWidth"
              objectPosition="center"
              className="h-full object-cover"
            />
          }
        />
      }

      {/* {
        // Breadcrumb
        <div className="relative bg-gray-50">
          <div className="max-w-7xl mx-auto px-6 pt-6 sm:px-10">

            <ol className="flex ml-0 space-x-4">
              <li className="mb-0">
                <div>
                  <Link to="/" className="no-underline">
                    <p className="text-sm text-gray-400 hover:text-primary-600">Home</p>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name} className="mb-0">
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <Link
                      to={page.href}
                      className="no-underline ml-4 text-sm text-gray-400 hover:text-primary-600"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>

          </div>
        </div>
      } */}

      {
        // Contact Information and Form
        <div className="relative mt-0 bg-gradient-to-b from-white to-gray-50" aria-labelledby="contact-heading">
          <div className="mx-auto">
            <div className="relative bg-gradient-to-br from-gray-100 to-gray-100 shadow-lg">

              <div className="grid grid-cols-1">
                {/* Contact Information */}
                <div className="relative overflow-hidden bg-gradient-to-b from-primary-400 to-primary-600 py-10 px-6 sm:px-10 xl:p-12">
                  {/* Decorative angle backgrounds */}
                  <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={343}
                      height={388}
                      viewBox="0 0 343 388"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear1"
                          x1="254.553"
                          y1="107.554"
                          x2="961.66"
                          y2="814.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={359}
                      height={339}
                      viewBox="0 0 359 339"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear2"
                          x1="192.553"
                          y1="28.553"
                          x2="899.66"
                          y2="735.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={160}
                      height={678}
                      viewBox="0 0 160 678"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear3"
                          x1="192.553"
                          y1="325.553"
                          x2="899.66"
                          y2="1032.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>

                  {/* Left side panel */}
                  <h3 className="text-lg font-bold text-white">Contact Information</h3>
                  <div className="mt-6">
                    <p className="max-w-3xl text-base text-primary-100 mb-4">
                      Please give us a call or message us through our official Facebook page to request a quote.
                    </p>
                  </div>
                  <dl className="mt-8 space-y-6 mb-0">
                    <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-primary-100">
                      <PhoneIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                      <a href="tel:+61431697414" className="no-underline hover:text-white">
                        <span className="ml-3">0431697414</span>
                      </a>
                    </dd>

                    <dt>
                      <span className="sr-only">Facebook</span>
                    </dt>
                    <dd className="flex text-base text-primary-100">
                      <FacebookIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                      <a href="https://www.facebook.com/dimensionlab.AU" className="no-underline hover:text-white">
                        <span className="ml-3">Dimension Lab</span>
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>

              {/*
              // WIP Contact Form
              <div className="grid grid-cols-1 lg:grid-cols-3">
                // Contact Information
                <div className="relative overflow-hidden bg-gradient-to-b from-primary-400 to-primary-600 py-10 px-6 sm:px-10 xl:p-12">
                  // Decorative angle backgrounds
                  <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={343}
                      height={388}
                      viewBox="0 0 343 388"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear1"
                          x1="254.553"
                          y1="107.554"
                          x2="961.66"
                          y2="814.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={359}
                      height={339}
                      viewBox="0 0 359 339"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear2"
                          x1="192.553"
                          y1="28.553"
                          x2="899.66"
                          y2="735.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={160}
                      height={678}
                      viewBox="0 0 160 678"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear3"
                          x1="192.553"
                          y1="325.553"
                          x2="899.66"
                          y2="1032.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>

                  // Left side panel
                  <h3 className="text-lg font-bold text-white">Contact Information</h3>
                  <div className="mt-6">
                    <p className="max-w-3xl text-base text-primary-100 mb-4">
                      To assist us with your enquiry, please submit your information through the contact form.
                      You should expect a response from us within 1-2 business days.
                    </p>
                  </div>
                  <dl className="mt-8 space-y-6 mb-0">
                    <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-primary-100">
                      <PhoneIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                      <a href="tel:+61431697414" className="no-underline hover:text-white">
                        <span className="ml-3">0431697414</span>
                      </a>
                    </dd>

                    <dt>
                      <span className="sr-only">Facebook</span>
                    </dt>
                    <dd className="flex text-base text-primary-100">
                      <FacebookIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                      <a href="https://www.facebook.com/dimensionlab.AU" className="no-underline hover:text-white">
                        <span className="ml-3">Dimension Lab</span>
                      </a>
                    </dd>
                  </dl>
                </div>

                // Contact Form
                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                  <h3 className="text-lg font-semibold text-primary-800">Send an Enquiry</h3>
                  <Formik
                    initialValues={{ ...INITIAL_FORM_STATE }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => submitForm(values)}
                  >
                    <Form className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                      <div>
                        <label htmlFor="firstName" className="block text-sm font-normal text-primary-800">
                          First Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="John"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="lastName" className="block text-sm font-normal text-primary-800">
                          Last Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Smith"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-normal text-primary-800">
                          Email Address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="you@example.com"
                            autoComplete="email"
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                          />
                        </div>
                        <p className="mt-1 mb-0 text-sm text-red-600" id="email-error">
                          An invalid email address has been detected.
                        </p>
                      </div>
                      <div>
                        <div className="flex">
                          <label htmlFor="phone" className="block text-sm font-normal text-primary-800">
                            Phone
                          </label>
                          <span id="phone-optional" className="ml-2 text-sm italic text-primary-600">(Optional)</span>
                        </div>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="0412345678"
                            autoComplete="tel"
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                            aria-describedby="phone-optional"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label htmlFor="subject" className="block text-sm font-normal text-primary-800">
                          Subject
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                            placeholder="Quote Enquiry"
                            defaultValue={''}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between">
                          <label htmlFor="message" className="block text-sm font-normal text-primary-800">
                            Message
                          </label>
                          <span id="message-max" className="italic text-sm text-primary-600">
                            (Max. 500 characters)
                          </span>
                        </div>
                        <div className="mt-1">
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            className="block w-full rounded-md border-gray-300 bg-gray-50 py-3 px-4 text-primary-800 shadow-sm focus:border-primary-600 focus:ring-primary-600"
                            aria-describedby="message-max"
                            placeholder="Insert Message Here"
                            defaultValue={''}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2 sm:flex sm:justify-end">
                        <button
                          type="submit"
                          className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-base font-normal text-white shadow-sm hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 sm:w-auto"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              */}


            </div>
          </div>
        </div>
      }


    </Layout>
  )
}

export default ContactPage
